import React from 'react';
import './App.css';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Removed Route import


import Plans from './Plans';
import Home from './Home';
import Work from './Work';
import Contact from './Contact';

function App() {
 
  return (
    <Router>
      
        <Routes>
          <Route exact path='/' Component={Home} /> {/* Use component instead of Component */}
          <Route path='/plans' Component={Plans} /> {/* Use element instead of Component */}
          <Route path='/work' Component={Work} /> {/* Use element instead of Component */}
          <Route path='/contact' Component={Contact} /> {/* Use element instead of Component */}
  
        </Routes>
   
    </Router>
  )
}

export default App;
