
import './App.css';

import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Plans from './Plans';
import React, { useEffect } from 'react';

function Home() {
  useEffect(() => {
    document.title = 'Neodia Web | Home';
  }, []);
  var limit = []
  return (
    <div onLoad={()=>{
      const layer1 = document.getElementById('layer1');
    const layer2 = document.getElementById('layer2');
    const layer3 = document.getElementById('layer3');
    const layer4 = document.getElementById('layer4');
    const layer5 = document.getElementById('layer5');
    const biglogo = document.getElementById('big');
    const firstSection = document.getElementById('first-section');
    const layer6 = document.getElementById('layer6');
  console.log('h');
    window.addEventListener('scroll', () => {
      let value = window.scrollY;
      var opacity = Math.abs((value)/(firstSection.getBoundingClientRect().bottom)-1)
     
      layer6.style.opacity = opacity
      layer2.style.marginTop = value*(-.6)+'px'
      biglogo.style.marginTop = value*(2.5)+'px'
      layer2.style.zIndex = '-1'
      layer3.style.zIndex = '-1'
      if(biglogo.getBoundingClientRect().top>firstSection.getBoundingClientRect().top){
        biglogo.style.display = 'none'
       limit.push(firstSection.getBoundingClientRect().top) 
        
        if(limit[0]<firstSection.getBoundingClientRect().top+400){
          biglogo.style.display = 'block'
          console.log('hello');
        }
      }
     
      layer3.style.marginTop = value*(-.3)+'px'
     
      // Your scroll event handling code here
    });
    }} className="App">
      <div className='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')
               
        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'
            
            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'
            
            
            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del2" ></div>
      </div>
      <header id='header'>
        <nav className='navbar' >
          <img className='logonav' src='/neodialogo.png' ></img>
          <ul className='navlinks' >
            <li className='onlink' >Home</li>
            <Link style={{textDecoration:'none'}} to='/plans' ><li> Plans</li></Link>
            <Link style={{textDecoration:'none'}} to='/work' ><li>Work</li></Link>
            <Link style={{textDecoration:'none'}} to='/contact' ><li>Contact</li></Link>
          </ul>
        </nav>
      </header>
     
      <section className='paralax' >
      <img id='layer1' className='paralax-img' src='/background-paralax-img/1-layer.png' ></img>
      <img id='layer2' className='paralax-img' src='/background-paralax-img/2-layer.png' ></img>
      <img id='layer3' className='paralax-img' src='/background-paralax-img/3-layer.png' ></img>
      <img id='layer4' className='paralax-img' src='/background-paralax-img/4-layer.png' ></img>
      <img id='big' className='big-logo' src='/neodialogo.png' ></img>
      <img id='layer5' className='paralax-img layer5' src='/background-paralax-img/5-layer.png' ></img>
      <img id='layer6' className='paralax-img layer6' src='/arrow-mouse.png' ></img>

      </section>
      <section className='noparalax' >
        <img className='biglogonoparalax' src='/neodialogo.png' ></img>
      </section>
     
      <section className='first-section' id='first-section' >
        <div className='first-text' >

      <h2>Neodia Web will make the website that <span>matches</span> you <span className='comp-span' >COMPLETELY</span></h2>
      <img src='/undraw_web_development_0l6vb.png' ></img>
        </div>
      
      <div className='grid'> 
      
      <svg className='pics'  height="15" viewBox="0 0 1905 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1920 15L1905.25 -9.53674e-07L1890.75 15L1875 -9.53674e-07L1860 15L1845 -9.53674e-07L1830 15L1815 -9.53674e-07L1800 15L1785 -9.53674e-07L1770 15L1755 -9.53674e-07L1740 15L1725 -9.53674e-07L1710 15L1695 -9.53674e-07L1680 15V0L1800 0L1920 0V15Z" fill="#120D17"/>
        <path d="M1680 15L1665.25 -9.53674e-07L1650.75 15L1635 -9.53674e-07L1620 15L1605 -9.53674e-07L1590 15L1575 -9.53674e-07L1560 15L1545 -9.53674e-07L1530 15L1515 -9.53674e-07L1500 15L1485 -9.53674e-07L1470 15L1455 -9.53674e-07L1440 15V0L1560 0L1680 0V15Z" fill="#120D17"/>
        <path d="M1440 15L1425.25 -5.96046e-08L1410.75 15L1395 -5.96046e-08L1380 15L1365 -5.96046e-08L1350 15L1335 -5.96046e-08L1320 15L1305 -5.96046e-08L1290 15L1275 -5.96046e-08L1260 15L1245 -5.96046e-08L1230 15L1215 -5.96046e-08L1200 15V8.9407e-07L1320 8.9407e-07L1440 8.9407e-07V15Z" fill="#120D17"/>
        <path d="M1200 15L1185.25 -9.53674e-07L1170.75 15L1155 -9.53674e-07L1140 15L1125 -9.53674e-07L1110 15L1095 -9.53674e-07L1080 15L1065 -9.53674e-07L1050 15L1035 -9.53674e-07L1020 15L1005 -9.53674e-07L990 15L975 -9.53674e-07L960 15V0L1080 0L1200 0V15Z" fill="#120D17"/>
        <path d="M960 15L945.25 -9.53674e-07L930.75 15L915 -9.53674e-07L900 15L885 -9.53674e-07L870 15L855 -9.53674e-07L840 15L825 -9.53674e-07L810 15L795 -9.53674e-07L780 15L765 -9.53674e-07L750 15L735 -9.53674e-07L720 15V0L840 0L960 0V15Z" fill="#120D17"/>
        <path d="M720 15L705.25 -9.53674e-07L690.75 15L675 -9.53674e-07L660 15L645 -9.53674e-07L630 15L615 -9.53674e-07L600 15L585 -9.53674e-07L570 15L555 -9.53674e-07L540 15L525 -9.53674e-07L510 15L495 -9.53674e-07L480 15V0L600 0L720 0V15Z" fill="#120D17"/>
        <path d="M480 15L465.25 -9.53674e-07L450.75 15L435 -9.53674e-07L420 15L405 -9.53674e-07L390 15L375 -9.53674e-07L360 15L345 -9.53674e-07L330 15L315 -9.53674e-07L300 15L285 -9.53674e-07L270 15L255 -9.53674e-07L240 15V0L360 0L480 0V15Z" fill="#120D17"/>
        <path d="M240 15L225.25 -9.53674e-07L210.75 15L195 -9.53674e-07L180 15L165 -9.53674e-07L150 15L135 -9.53674e-07L120 15L105 -9.53674e-07L90 15L75 -9.53674e-07L60 15L45 -9.53674e-07L30 15L15 -9.53674e-07L0 15V0L120 0L240 0V15Z" fill="#120D17"/>
        </svg>
        <div onMouseEnter={()=>{
          console.log(window.screen.width);
          if (window.screen.width>739) {
            document.getElementById('underline1').style.width = '100%'
          }
          
        }} onMouseLeave={()=>{
          if (window.screen.width>739) {
            document.getElementById('underline1').style.width = '50%'
          }
          
        }} className='text-combats un noir rose'  ><h6>Neodia Web makes several appointments with you to fit your needs.<div id='underline1' className='underline' ></div></h6>  </div>
        <div style={{}} className='img-combats deux' ></div>
        <div style={{background:'url("/designing-img.jpeg")', backgroundPositionY:'60%', backgroundSize:'120%'}} className='img-combats trois' ></div>
        <div onMouseEnter={()=>{
          if (window.screen.width>739) {
            document.getElementById('underline2').style.width = '100%'
          }
          
        }} onMouseLeave={()=>{
          if (window.screen.width>739) {
            document.getElementById('underline2').style.width = '50%'
          }
          
        }} className='text-combatsr quatre rouge'  ><h6  >Neodia Web creates our own designs to reflect your image.<div id='underline2' className='underline underline-right' ></div></h6> </div>
        <div onMouseEnter={()=>{
          if (window.screen.width>739) {
            document.getElementById('underline3').style.width = '100%'
          }
          
        }} onMouseLeave={()=>{
          if (window.screen.width>739) {
            document.getElementById('underline3').style.width = '50%'
          }
          
        }} className='text-combats cinq noir ' ><h6>Neodia Web entirely program your 
        website to give you complete 
        freedom of design.<div id='underline3' className='underline' ></div></h6></div>
        <div style={{background:'url("/programming-img.jpeg")', backgroundPositionY:'60%', backgroundSize:'120%'}} className='img-combats six' ></div>
        
        <svg className='pics-reverse' height="15" viewBox="0 0 1905 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1920 15L1905.25 -9.53674e-07L1890.75 15L1875 -9.53674e-07L1860 15L1845 -9.53674e-07L1830 15L1815 -9.53674e-07L1800 15L1785 -9.53674e-07L1770 15L1755 -9.53674e-07L1740 15L1725 -9.53674e-07L1710 15L1695 -9.53674e-07L1680 15V0L1800 0L1920 0V15Z" fill="#120D17"/>
        <path d="M1680 15L1665.25 -9.53674e-07L1650.75 15L1635 -9.53674e-07L1620 15L1605 -9.53674e-07L1590 15L1575 -9.53674e-07L1560 15L1545 -9.53674e-07L1530 15L1515 -9.53674e-07L1500 15L1485 -9.53674e-07L1470 15L1455 -9.53674e-07L1440 15V0L1560 0L1680 0V15Z" fill="#120D17"/>
        <path d="M1440 15L1425.25 -5.96046e-08L1410.75 15L1395 -5.96046e-08L1380 15L1365 -5.96046e-08L1350 15L1335 -5.96046e-08L1320 15L1305 -5.96046e-08L1290 15L1275 -5.96046e-08L1260 15L1245 -5.96046e-08L1230 15L1215 -5.96046e-08L1200 15V8.9407e-07L1320 8.9407e-07L1440 8.9407e-07V15Z" fill="#120D17"/>
        <path d="M1200 15L1185.25 -9.53674e-07L1170.75 15L1155 -9.53674e-07L1140 15L1125 -9.53674e-07L1110 15L1095 -9.53674e-07L1080 15L1065 -9.53674e-07L1050 15L1035 -9.53674e-07L1020 15L1005 -9.53674e-07L990 15L975 -9.53674e-07L960 15V0L1080 0L1200 0V15Z" fill="#120D17"/>
        <path d="M960 15L945.25 -9.53674e-07L930.75 15L915 -9.53674e-07L900 15L885 -9.53674e-07L870 15L855 -9.53674e-07L840 15L825 -9.53674e-07L810 15L795 -9.53674e-07L780 15L765 -9.53674e-07L750 15L735 -9.53674e-07L720 15V0L840 0L960 0V15Z" fill="#120D17"/>
        <path d="M720 15L705.25 -9.53674e-07L690.75 15L675 -9.53674e-07L660 15L645 -9.53674e-07L630 15L615 -9.53674e-07L600 15L585 -9.53674e-07L570 15L555 -9.53674e-07L540 15L525 -9.53674e-07L510 15L495 -9.53674e-07L480 15V0L600 0L720 0V15Z" fill="#120D17"/>
        <path d="M480 15L465.25 -9.53674e-07L450.75 15L435 -9.53674e-07L420 15L405 -9.53674e-07L390 15L375 -9.53674e-07L360 15L345 -9.53674e-07L330 15L315 -9.53674e-07L300 15L285 -9.53674e-07L270 15L255 -9.53674e-07L240 15V0L360 0L480 0V15Z" fill="#120D17"/>
        <path d="M240 15L225.25 -9.53674e-07L210.75 15L195 -9.53674e-07L180 15L165 -9.53674e-07L150 15L135 -9.53674e-07L120 15L105 -9.53674e-07L90 15L75 -9.53674e-07L60 15L45 -9.53674e-07L30 15L15 -9.53674e-07L0 15V0L120 0L240 0V15Z" fill="#120D17"/>
        </svg>
         </div>
        
      </section>
      <section className='creations-section' >
        <h2 style={{marginTop:'3em'}} >Neodia's creations and skills</h2>
        <div   className='wrap-absolute' >
        <div style={{marginTop:'4em'}} className='wrap-frame' >
        <div className='outerframe' >
        <div style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}} className='frame' >
          <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
        </div>
        </div>
        <div className='outerframe' >
        <div onClick={()=>{
            window.open(
              "https://galaxy-olive-six.vercel.app", "_blank")
          }}  style={{backgroundImage:'url("/galaxy.png")'}} className='frame'>
        
        </div>
        </div>
        <div className='outerframe' >
        <div style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}} className='frame' >
          <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
        
        </div>
        </div>
        </div>
        <div className='wrap-frame' >
        <div className='outerframe' >
        <div style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}} className='frame' >
        <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
        </div>
        </div>
        <div className='outerframe' >

       
        <div style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}} className='frame' >
        <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
        </div>
        </div>
        <div className='outerframe' >
        <div style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}} className='frame' >
        <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
        
        
        </div>
        </div>
        
        </div>
        <div className='wrap-frame' >
        
        
        </div>
        <div className='wrap-h3' >

        
        </div>
        
        </div>
        <div className='wrap-socials' >
        <h3 className='h3-socials' >Want to join us?</h3>
        <div className='line-socials' ></div>
        <div className='socials' >
        <Link style={{textDecoration:'none'}} to='/contact' > <a>Contact</a></Link>
          <div className='div-socials' >
          <img style={{cursor:'pointer'}}  onClick={()=>{
            window.open(
              "https://x.com/Neodia_web", "_blank")
          }} src='/X.png' ></img>
          <img style={{cursor:'pointer'}} onClick={()=>{
            window.open(
              "https://www.facebook.com/profile.php?id=61556572195172", "_blank")
          }} src='/facebook.png' ></img>
          <img style={{cursor:'pointer'}} onClick={()=>{
            window.open(
              "https://www.instagram.com/neodiaweb/", "_blank")
          }} src='/instagram.png' ></img>
          </div>
          
        </div>
        </div>
        
        
      </section>

      
    </div>
    
  );
  
  }
    


export default Home;
