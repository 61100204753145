import "./Contact.css"
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, onValue, update, get, child, remove } from "firebase/database"; 
import { doc } from "firebase/firestore";
import React, { useEffect } from 'react';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


function Contact() {
    useEffect(() => {
        document.title = 'Neodia Web | Contact';
      }, []);
        
    
    
    return(
        <div className="main_contact" id="main_contact"  >
            <div className='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')
               
        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'
            
            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'
            
            
            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del2" ></div>
      </div>
        <header id='header'>
        <nav className='navbar' >
          <img className='logonav' src='/neodialogo.png' ></img>
          <ul className='navlinks' >
          <Link style={{textDecoration:'none'}} to='/' ><li className='onlink' >Home</li></Link>
          <Link style={{textDecoration:'none'}} to='/plans' ><li> Plans</li></Link>
            <Link style={{textDecoration:'none'}} to='/work' ><li>Work</li></Link>  
            <li>Contact</li>
          </ul>
        </nav>
      </header>

      <div className="wrap_contact" >
        <div className="wrap_img_contact" >

        <img className="img_contact"  src="/contact/contactus.svg" ></img>
        </div>
        <div className="wrap_right_contact" >
            <h2>Contact Us</h2>
            <div className="textalign_left" >
            <input id="name_input" placeholder="Your name" className="name_input input_contact" ></input> <br></br>
            <input id="mail_input" placeholder="Your mail" className="mail_input input_contact" ></input> <br></br>
            <textarea id="project_input" placeholder="Talk about your project..." className="project_input input_contact" ></textarea> <br></br>
            </div>
           <button className="button_contact" id="button_contact" onClick={()=>{
            const firebaseConfig = {
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                databaseURL: 'https://neodia-2db4f-default-rtdb.europe-west1.firebasedatabase.app/',
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_FIREBASE_APP_ID,
                measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
              };
              
              // Initialize Firebase
              const app = initializeApp(firebaseConfig);
            const name = document.getElementById('name_input')
            const mail = document.getElementById('mail_input')
            
            mail.style.borderColor = 'gray'
            name.style.borderColor = 'gray'
            const project = document.getElementById('project_input')
            console.log(name);
            if (mail.value != '' && mail.value.includes('@') && name.value != '') {
                const database = getDatabase()
        
            update(ref(database, name.value+mail.value.replace(/[^\w\s]/gi, '')),{
            Name:name.value,
            Mail: mail.value,
            Project: project.value
           
           
            
          }).then((result) => {
            document.getElementById('button_contact').style.animationName = 'go'
            mail.value = ''
            name.value = ''
            project.value = ''
            


          })
            }else{
                if(name.value == '' ){
                    name.style.borderColor = 'red'
                }
                else if(mail.value == '' || mail.value.includes('@') == false){
                    mail.style.borderColor = 'red'
                }
            }
            

           }} >Send</button>
            <div  className="socials_contact_wrap" style={{marginBottom:'2em'}} >
                <h4>Our socials</h4>
                <div  className="socials_contact" >
                    <img style={{cursor:'pointer'}} onClick={()=>{
            window.open(
              "https://x.com/Neodia_web", "_blank")
          }} className="img_social_X img_social"  src="/contact/X.svg" ></img>
                    <img style={{cursor:'pointer'}} onClick={()=>{
            window.open(
              "https://www.facebook.com/profile.php?id=61556572195172", "_blank")
          }} className="img_social_facebook img_social" src="/contact/facebook.svg" ></img>
                    <img  style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>{
            window.open(
              "https://www.instagram.com/neodiaweb/", "_blank")
          }} className="img_social_instagram img_social" src="/contact/instagram.svg" ></img>
                    <img  style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>{
            window.open(
              "mailto: contact@neodiaweb.com")
          }} className="img_social_instagram img_social" src="/contact/email.svg" ></img>
                </div>
            </div>
        </div>
      </div>
        </div>
    )
}
export default Contact;