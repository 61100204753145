import './Plans.css';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
function Plans(params) {
    useEffect(() => {
        document.title = 'Neodia Web | Plans';
      }, []);
    return(
        <div className='main' >
            <div className='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')
               
        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'
            
            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'
            
            
            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del2" ></div>
      </div>
        <header id='header'>
        <nav className='navbar' >
          <img className='logonav' src='/neodialogo.png' ></img>
          <ul className='navlinks' >
          <Link style={{textDecoration:'none'}} to='/' ><li className='onlink' >Home</li></Link>
            <li> Plans</li>
            <Link style={{textDecoration:'none'}} to='/work' ><li>Work</li></Link>  
            <Link style={{textDecoration:'none'}} to='/contact' ><li>Contact</li></Link>
          </ul>
        </nav>
      </header>
      
            <div className='bloc' >
                <h1>Our Plans</h1> 
                <div className='wrap_mobile wrap' >
               
                <div className='superior-wrap' >
                    <div className='superior-div plans-div' >
                    <div className='superior card' >
                    <h2>Superior</h2>
                            <ul>
                            <li>Personalized design</li>
                                <li>Starts At 400€</li>
                                <li>Free Professional Email</li>
                                <li>Online Store</li>
                                <li>unlimited appointments</li>
                           
                            </ul>
                    </div>
                    </div>
                </div>
                <div className='standard-wrap' >
                    <div className='standard-div plans-div' >
                    <div className='standard card' >
                    <h2>Standard</h2>
                            <ul>
                                <li>Modified Templates To Match You</li>
                                <li>Starts At 200€</li>
                                <li>Max. 1 week of work</li>
                                <li>No Online Store</li>
                            </ul>
                    </div>
                    </div>
                </div>
                <div className='premium-wrap' >
                    <div className='premium-div plans-div ' >
                        <div className='premium card' >
                            <h2>Premium</h2>
                            <ul>
                            <li>3D available</li>
                                <li>Free Showcase Site for the waiting</li>
                                <li>Free Professional Email</li>
                                <li>Online Store</li>
                                <li>unlimited appointments</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                <div className='wrap desktop_wrap' >
                <div className='premium-wrap' >
                    <div className='premium-div plans-div ' >
                        <div className='premium card' >
                            <h2>Premium</h2>
                            <ul>
                          
                                <li>3D available</li>
                                <li>Free Showcase Site for the waiting</li>
                                <li>Free Professional Email</li>
                                <li>Online Store</li>
                                <li>unlimited appointments</li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='superior-wrap' >
                    <div className='superior-div plans-div' >
                    <div className='superior card' >
                    <h2>Superior</h2>
                            <ul>
                            <li>Personalized design</li>
                                <li>Starts At 400€</li>
                                <li>Free Professional Email</li>
                                <li>Online Store</li>
                                <li>unlimited appointments</li>
                            </ul>
                    </div>
                    </div>
                </div>
                <div className='standard-wrap' >
                    <div className='standard-div plans-div' >
                    <div className='standard card' >
                    <h2>Standard</h2>
                            <ul>
                                <li>Showcase Site</li>
                                <li>Starts At 200€</li>
                                <li>After 1 week</li>
                                <li>No Online Store</li>
                            </ul>
                    </div>
                    </div>
                </div>
                </div>
                <Link className='contact_btn_plans_link' to='/contact' >   <button className='contact_btn_plans' > Contact Us</button></Link>
            </div>

        </div>
    )
}
export default Plans;