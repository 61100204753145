import './Work.css'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
function Work() {
  useEffect(() => {
    document.title = 'Neodia Web | Work';
  }, []);
    return(
        <div className='main' >
          
           <div className='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')
               
        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'
            
            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'
            
            
            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out'}} id="hamb_del2" ></div>
      </div>
        <header id='header'>
        <nav className='navbar' >
          <img className='logonav' src='/neodialogo.png' ></img>
          <ul className='navlinks' >
          <Link style={{textDecoration:'none'}} to='/' ><li className='onlink' >Home</li></Link>
          <Link style={{textDecoration:'none'}} to='/plans' ><li> Plans</li></Link>  
            <li>Work</li>
            <Link style={{textDecoration:'none'}} to='/contact' ><li>Contact</li></Link>
          </ul>
        </nav>
      </header>
      <div className='wrap_work' >
      <h2>
        Our Work
      </h2>
      <div className='wrap_cards_work' >
        <div className='first_work_group work_group' >
           <div className='card_line_work1 card_line_work' onClick={()=>{
                  window.open(
                    "https://galaxy-olive-six.vercel.app", "_blank")
           }}  ><div className='card_work1 card_work'   >
              
            </div></div> 
           <div className='card_line_work2 card_line_work'  onClick={()=>{
            
          }}    ><div className='card_work2 card_work' style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}} >
            <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
            </div></div> 
        </div>
        <div className='second_work_group work_group' >
        <div className='card_line_work3 card_line_work'  onClick={()=>{
             
          }}   ><div className='card_work3 card_work' style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}}   >
            <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
            </div></div> 
        <div className='card_line_work4 card_line_work' onClick={()=>{
            
          }}    > <div className='card_work4 card_work' style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}}  >
            <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
            </div></div>  

        </div>
        <div className='last_work_group work_group' >
        <div className='card_line_work5 card_line_work' onClick={()=>{
            
          }}   ><div className='card_work5 card_work' style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}}  >
            <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
            </div></div>   
        <div className='card_line_work6 card_line_work' onClick={()=>{
            
          }}   ><div className='card_work6 card_work' style={{backgroundColor:'rgb(231 231 231)', backgroundSize:'100%', position:'relative', cursor:'default'}}  >
            <h5 style={{margin:'0', color:'rgb(51 51 51)', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} >Working on projects...</h5>
            </div></div>    

        </div>
      </div>
      </div>
      
            
        </div>
    )
}
export default Work;